<template>
  <b-card>
    <validation-observer
      ref="documentRules"
    >
      <div>
        <div class="d-flex align-items-center">
          <img
            alt=""
            src="@/assets/images/Vector.svg"
          >
          <h5 class="color-text">
            Thông tin người nộp hồ sơ
          </h5>
        </div>
        <hr class="color-hr">
      </div>
      <b-form-radio-group
        v-model="formModel.kieuNguoiNop"
        :options="[
          { text: 'Công dân', value: 1 },
          { text: 'Tổ chức/ Doanh nghiệp', value: 2 },
        ]"
      />
      <b-row class="mt-1">
        <b-col
          md="5"
          lg="5"
          sm="5"
        >
          <b-form-group
            :label="formModel.kieuNguoiNop === 1 ? 'Số CCCD/CMND' : 'Mã số thuế'"
            class="required"
          >
            <validation-provider
              v-slot="{ errors }"
              key="CMND"
              name="CMND"
              :rules="{ required: true }"
            >
              <b-form-input
                ref="CMND"
                v-model="formModel.nguoiNop_CMND_HoChieu"
                :auto-focus="true"
                type="text"
                required
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          lg="3"
          sm="3"
        >
          <b-form-group
            :label="formModel.kieuNguoiNop === 1 ? 'Họ và tên' : 'Tên tổ chức/doanh nghiệp'"
            class="required"
          >
            <validation-provider
              v-slot="{ errors }"
              key="hoVaTen"
              name="hoVaTen"
              :rules="{ required: true }"
            >
              <b-form-input
                id="hoVaTen"
                v-model="formModel.nguoiNop_HoVaTen"
                type="text"
                required
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          lg="3"
          sm="3"
        >
          <b-form-group
            label="Ngày sinh"
          >
            <date-picker
              v-model="formModel.ngaySinh"
              class="w-100"
              value-type="format"
              format="DD/MM/YYYY"
              placeholder="Chọn ngày"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="1"
          lg="1"
          sm="1"
        >
          <b-button
            :disabled="!(formModel.nguoiNop_CMND_HoChieu && formModel.nguoiNop_HoVaTen && formModel.ngaySinh)"
            style="margin-top: 30px"
            class="mr-1"
            variant="primary"
            size="sm"
            @click="kiemtraCongDan"
          >
            Kiểm tra
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          lg="12"
          sm="12"
        >
          <b-form-group label="Địa chỉ">
            <b-form-input
              v-model="formModel.nguoiNop_DiaChi"
              class="word-break"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group label="Tỉnh/ thành phố">
            <treeselect
              v-model="formModel.nguoiNop_TinhId"
              v-format-v-select
              :options="tinhThanhPho"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              @input="layDanhSachQuanHuyen($event, {
                danhSachHuyen: 'quanHuyen',
                huyenModel: 'nguoiNop_HuyenId',
                xaModel: 'nguoiNop_XaId',
              })"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group label="Quận/ huyện">
            <treeselect
              v-model="formModel.nguoiNop_HuyenId"
              v-format-v-select
              :options="quanHuyen"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="!formModel.nguoiNop_TinhId"
              @input="layDanhSachXaPhuong($event, {
                danhSachXa: 'xaPhuong',
                xaModel: 'nguoiNop_XaId',
              })"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group label="Xã/ phường">
            <treeselect
              v-model="formModel.nguoiNop_XaId"
              v-format-v-select
              :options="xaPhuong"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="!formModel.nguoiNop_HuyenId"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group
            label="Số điện thoại"
            class="required"
          >
            <validation-provider
              v-slot="{ errors }"
              key="soDienThoai"
              name="soDienThoai"
              :rules="'required|phone'"
            >
              <b-form-input
                id="soDienThoai"
                ref="soDienThoai"
                v-model="formModel.nguoiNop_SoDienThoai"
                v-block-e
                type="number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
              <span class="text-danger">{{ errors[1] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          lg="8"
          sm="6"
        >
          <b-form-group
            label="Địa chỉ email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="email"
            >
              <b-form-input v-model="formModel.nguoiNop_DiaChiEmail" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <div class="form-item d-flex">
            <b-form-checkbox v-model="formModel.dichVuSms" />
            <div class="mr-2">
              Dịch vụ SMS
            </div>
          </div>
        </b-col>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <div class="form-item d-flex">
            <b-form-checkbox
              v-model="nguoiNopVaChuHoSoGiongNhau"
            />
            <div class="mr-2">
              Nộp hộ
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="nguoiNopVaChuHoSoGiongNhau">
        <div class="mt-2">
          <font-awesome-icon
            :icon="faTag"
            class="mr-1"
          />
          Thông tin hồ sơ
        </div>
        <hr class="mt-0">
        <b-form-radio-group
          v-model="formModel.kieuChuHoSo"
          :options="[
            { text: 'Công dân', value: 0 },
            { text: 'Tổ chức/ Doanh nghiệp', value: 1 },
          ]"
        />
        <b-row class="mt-1">
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group :label="formModel.kieuChuHoSo === 1 ? 'Số CCCD/CMND' : 'Mã số thuế'">
              <b-form-input v-model="formModel.chuHoSo_CMND_HoChieu" />
            </b-form-group>
          </b-col>
          <b-col
            md="8"
            lg="8"
            sm="6"
          >
            <b-form-group
              :label="formModel.kieuChuHoSo === 0 ? 'Họ và tên' : 'Tên tổ chức/doanh nghiệp'"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="hoVaTen1"
                rules="required"
              >
                <b-form-input
                  id="hoVaTen1"
                  v-model="formModel.chuHoSo_HoVaTen"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            lg="12"
            sm="12"
          >
            <b-form-group label="Địa chỉ">
              <b-form-input
                v-model="formModel.chuHoSo_DiaChi"
                class="word-break"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group label="Tỉnh/ thành phố">
              <treeselect
                v-model="formModel.chuHoSo_TinhId"
                v-format-v-select
                :options="tinhThanhPho"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                placeholder=""
                :clear-on-select="true"
                :normalizer="normalizer"
                @input="layDanhSachQuanHuyen($event, {
                  danhSachHuyen: 'quanHuyen1',
                  huyenModel: 'chuHoSo_HuyenId',
                  xaModel: 'chuHoSo_XaId',
                })"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group label="Quận/ huyện">
              <treeselect
                v-model="formModel.chuHoSo_HuyenId"
                v-format-v-select
                :options="quanHuyen1"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                placeholder=""
                :clear-on-select="true"
                :normalizer="normalizer"
                :disabled="!formModel.chuHoSo_TinhId"
                @input="layDanhSachXaPhuong($event, {
                  danhSachXa: 'xaPhuong1',
                  xaModel: 'chuHoSo_XaId',
                })"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group label="Xã/ phường">
              <treeselect
                v-model="formModel.chuHoSo_XaId"
                v-format-v-select
                :options="xaPhuong1"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                placeholder=""
                :clear-on-select="true"
                :normalizer="normalizer"
                :disabled="!formModel.chuHoSo_HuyenId"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group
              label="Số điện thoại"
              class="required"
            >
              <validation-provider
                v-slot="{ errors }"
                key="soDienThoai"
                name="soDienThoai"
                :rules="'required|phone'"
              >
                <b-form-input
                  id="soDienThoai"
                  ref="soDienThoai"
                  v-model="formModel.chuHoSo_SoDienThoai"
                  v-block-e
                  type="number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="8"
            lg="8"
            sm="6"
          >
            <b-form-group
              label="Địa chỉ email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-input v-model="formModel.chuHoSo_DiaChiEmail" />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="mt-1">
        <div class="d-flex align-items-center">
          <img
            alt=""
            src="@/assets/images/Vector.svg"
          >
          <h5 class="color-text">
            Thông tin hồ sơ
          </h5>
        </div>
        <hr class="color-hr">
      </div>
      <b-row>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group
            label="Lĩnh vực"
            class="required"
          >
            <validation-provider
              v-slot="{ errors }"
              key="linhVuc"
              name="linhVuc"
              :rules="{ required: true}"
            >
              <treeselect
                id="linhVuc"
                ref="linhVuc"
                v-model="formModel.linhVucId"
                v-format-v-select
                :options="danhSachLinhVuc"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                placeholder=""
                :clear-on-select="true"
                :normalizer="normalizer"
                @select="layDanhSachThuTucHanhChinh"
              >
                <label
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName}"
                  :class="labelClassName"
                >
                  - {{ node.label }}
                </label>
              </treeselect>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group
            label="Lệ phí (VNĐ)"
            class="form-checkbox"
          >
            <b-form-input
              id="lePhiInput"
              ref="lePhiInput"
              type="text"
              placeholder="Nhập lệ phí"
              required
            />
            <b-form-checkbox
              v-model="formModel.lePhi_DaNop"
              class="w-25 mt-1 ml-1"
            >
              Đã nộp
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
          sm="6"
        >
          <b-form-group
            label="Phí HS (VNĐ)"
            class="form-checkbox"
          >
            <b-form-input
              id="phiInput"
              ref="phiInput"
              type="text"
              placeholder="Nhập phí"
              class="w-75"
            />
            <!-- <b-form-input
              v-model="formModel.phiHs"
              v-block-e
              v-format-positive-integers
              v-is-int
              class="w-75"
              type="number"
            /> -->
            <b-form-checkbox
              v-model="formModel.phiHs_DaNop"
              class="w-25 mt-1 ml-1"
            >
              Đã nộp
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          lg="12"
          sm="12"
        >
          <b-form-group
            label="Tên TTHC"
            class="required"
          >
            <validation-provider
              v-slot="{ errors }"
              key="thuTucHanhChinh"
              name="thuTucHanhChinh"
              :rules="{ required: true}"
            >
              <treeselect
                id="tthc"
                ref="thuTucHanhChinh"
                v-model="formModel.thuTucHanhChinhId"
                v-format-v-select
                :options="danhSachThuTucHanhChinh"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                placeholder=""
                :clear-on-select="true"
                :normalizer="normalizer"
                :disabled="!formModel.linhVucId"
                @select="layDanhSachMauHoSoMauHoSo($event); layDanhSachToKhai($event); setPhi($event);"
              >
                <label
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName}"
                  :title="node.label"
                  :class="labelClassName"
                >
                  {{ node.code }} - {{ node.label }}
                </label>
              </treeselect>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          lg="12"
          sm="12"
        >
          <b-form-group
            label="Mẫu hồ sơ"
            class="required"
          >
            <validation-provider
              v-slot="{ errors }"
              key="mauHoSo"
              name="mauHoSo"
              :rules="{ required: true}"
            >
              <treeselect
                id="mauHoSo"
                ref="mauHoSo"
                v-model="formModel.mauHoSoId"
                v-format-v-select
                :options="danhSachMauHoSo"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                placeholder=""
                :clear-on-select="true"
                :normalizer="normalizer"
                :disabled="!formModel.thuTucHanhChinhId"
                @select="setMauHoSoGiayTo"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          lg="6"
          sm="6"
        >
          <b-form-group label="Ngày, giờ nộp">
            <date-picker
              v-model="formModel.ngayNop"
              type="datetime"
              class="w-100 date-picker"
              format="DD/MM/YYYY HH:mm"
              :disabled="false"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          lg="6"
          sm="6"
        >
          <b-form-group label="Ngày hẹn trả KQ">
            <date-picker
              v-model="formModel.ngayHenTraKq"
              class="w-100 date-picker"
              format="DD/MM/YYYY HH:mm"
              :disabled="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          lg="6"
          sm="6"
        >
          <b-form-group label="Giờ hẹn trả từ">
            <date-picker
              v-model="formModel.gioHenTraTu"
              class="w-100 date-picker"
              type="time"
              format="HH:mm"
              :disabled-time="disableTimeTraTu"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          lg="6"
          sm="6"
        >
          <b-form-group label="Giờ hẹn trả đến">
            <date-picker
              v-model="formModel.gioHenTraDen"
              class="w-100 date-picker"
              type="time"
              format="HH:mm"
              :disabled-time="disableTimeTraDen"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          lg="12"
          sm="12"
        >
          <b-form-group label="Thông tin mô tả">
            <b-form-input v-model="formModel.thongTinMota" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ve-table
            :key="keyTable"
            border-y
            style="width:100%"
            :columns="columns"
            :table-data="danhSachMauHoSoGiayTo"
          />
          <div
            v-show="danhSachMauHoSoGiayTo && danhSachMauHoSoGiayTo.length === 0"
            class="empty-data"
          >
            Không có dữ liệu.
          </div>
        </b-col>
      </b-row>
      <div class="ve-table ve-table-border-around w-100 mt-2 custom-ve-table">
        <div
          class="ve-table-container"
          style="min-height: 91px"
        >
          <table class="mt-2 ve-table-content ve-table-border-x ve-table-border-y position-relative">
            <colgroup>
              <col style="width: 250px;">
              <col style="width: 150px;">
              <col style="width: 100px;">
            </colgroup>
            <tbody class="ve-table-body ve-table-row-hover ve-table-row-highlight">
              <tr class="ve-table-body-tr">
                <td
                  rowspan="1"
                  colspan="1"
                  col-key="b"
                  class="ve-table-body-td pl-0"
                  style="text-align: center; background-color: #f3f4f7;"
                >
                  <b-form-input
                    id="__BVID__629"
                    v-model="hoSoGiayToKhacModels.tenGiayTo"
                    placeholder="Nhập thông tin giấy tờ khác"
                    type="text"
                  />
                </td>
                <td
                  rowspan="1"
                  colspan="1"
                  col-key="e"
                  class="ve-table-body-td input-td"
                  style="text-align: left; max-width: 150px"
                >
                  <div
                    v-for="(file, index) in fileNameGiayToKhac"
                    :key="index"
                    class="file-color custom-grid-xl-container"
                  >
                    <span class="grap-11">{{ file }}</span>
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                      class="ml-1 grap-1 cursor-pointer"
                      @click="removeFile(file)"
                    />
                  </div>
                </td>
                <td
                  rowspan="1"
                  colspan="1"
                  col-key="f"
                  class="ve-table-body-td"
                  style="text-align: center;"
                >
                  <div
                    class="upload-btn-wrapper"
                    :title="''"
                  >
                    <button
                      class="btn"
                      style="width: 150px; cursor: pointer"
                    >
                      Chọn file tải lên
                    </button>
                    <input
                      ref="myfile"
                      type="file"
                      multiple
                      accept=".doc,.pdf,.xls,.docx,.xlsx,.zip,.rar,.jpg,.png,.bmp,.gif"
                      @change="changeFileGiayToKhac"
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-item d-flex form-hr">
        <b-form-checkbox v-model="formModel.dichVuChuyenPhatKetQua" />
        <div class="mr-2 color-text">
          Dịch vụ chuyển phát kết quả
        </div>
      </div>
      <hr class="color-hr mb-3">
      <div
        :class="formModel.dichVuChuyenPhatKetQua ? '' : 'd-none'"
      >
        <b-row>
          <b-col
            md="8"
            lg="8"
            sm="6"
          >
            <b-form-group
              label="Họ và tên"
              :class="{required: formModel.dichVuChuyenPhatKetQua}"
            >
              <validation-provider
                #default="{ errors }"
                name="hoVaTen2"
                :rules="formModel.dichVuChuyenPhatKetQua ? 'required' : ''"
              >
                <b-form-input
                  id="hoVaTen2"
                  v-model="formModel.chuyenPhat_HoTen"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group
              label="Số điện thoại"
              :class="{required: formModel.dichVuChuyenPhatKetQua}"
            >
              <validation-provider
                #default="{ errors }"
                name="soDienThoai2"
                :rules="formModel.dichVuChuyenPhatKetQua ? 'required|phone' : ''"
              >
                <b-form-input
                  id="soDienThoai2"
                  v-model="formModel.chuyenPhat_SoDienThoai"
                  v-block-e
                  type="number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group
              label="Tỉnh/ thành phố"
              :class="{required: formModel.dichVuChuyenPhatKetQua}"
            >
              <validation-provider
                #default="{ errors }"
                :rules="formModel.dichVuChuyenPhatKetQua ? 'required' : ''"
              >
                <treeselect
                  v-model="formModel.chuyenPhat_TinhId"
                  v-format-v-select
                  :options="tinhThanhPho"
                  :limit="0"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder=""
                  :clear-on-select="true"
                  :normalizer="normalizer"
                  @input="layDanhSachQuanHuyen($event, {
                    danhSachHuyen: 'quanHuyen2',
                    huyenModel: 'chuyenPhat_HuyenId',
                    xaModel: 'chuyenPhat_XaId',
                  })"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group
              label="Quận/ huyện"
              :class="{required: formModel.dichVuChuyenPhatKetQua}"
            >
              <validation-provider
                #default="{ errors }"
                :rules="formModel.dichVuChuyenPhatKetQua ? 'required' : ''"
              >
                <treeselect
                  v-model="formModel.chuyenPhat_HuyenId"
                  v-format-v-select
                  :options="quanHuyen2"
                  :limit="0"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder=""
                  :clear-on-select="true"
                  :normalizer="normalizer"
                  :disabled="!formModel.chuyenPhat_TinhId"
                  @input="layDanhSachXaPhuong($event, {
                    danhSachXa: 'xaPhuong2',
                    xaModel: 'chuyenPhat_XaId',
                  })"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="4"
            sm="6"
          >
            <b-form-group
              label="Xã/ phường"
              :class="{required: formModel.dichVuChuyenPhatKetQua}"
            >
              <validation-provider
                #default="{ errors }"
                :rules="formModel.dichVuChuyenPhatKetQua ? 'required' : ''"
              >
                <treeselect
                  v-model="formModel.chuyenPhat_XaId"
                  v-format-v-select
                  :options="xaPhuong2"
                  :limit="0"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder=""
                  :clear-on-select="true"
                  :normalizer="normalizer"
                  :disabled="!formModel.chuyenPhat_HuyenId"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            lg="12"
            sm="12"
          >
            <b-form-group
              label="Địa chỉ"
              :class="{required: formModel.dichVuChuyenPhatKetQua}"
            >
              <validation-provider
                #default="{ errors }"
                :rules="formModel.dichVuChuyenPhatKetQua ? 'required' : ''"
              >
                <b-form-input v-model="formModel.chuyenPhat_DiaChi" />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div class="mt-1">
        <div class="d-flex align-items-center">
          <img
            alt=""
            src="@/assets/images/Vector.svg"
          >
          <h5 class="color-text">
            Thông tin tờ khai
          </h5>
        </div>
        <hr class="color-hr">
      </div>
      <div v-if="formModel.thuTucHanhChinhId">
        <FormulateForm @submit="(formulateValidation = true)">
          <div
            v-for="(toKhai, index) in formControlView"
            :key="toKhai.name"
            class="mb-2"
          >
            <div
              v-b-toggle="toKhai.name"
              class="template-name"
              @click="(formControlView[index].active = !formControlView[index].active)"
            >
              <div>{{ toKhai.name }}</div>
              <feather-icon
                :class="{'show-template': toKhai.active}"
                icon="ChevronRightIcon"
                size="20"
              />
            </div>
            <b-collapse
              :id="toKhai.name"
              visible
            >
              <div
                :key="formKey"
                class="row"
              >
                <div
                  v-for="(row, formIndex) in toKhai.listRow"
                  :key="formIndex"
                  :class="row.className"
                >
                  <div class="row">
                    <div
                      v-for="(item, i) in row.listControl"
                      :key="i"
                      :class="item['wrap-class']"
                    >
                      <div
                        class="input-formulate"
                        :class="{ 'wrap-flex': item.horizontal} "
                      >
                        <FormulateInput
                          v-format-date
                          v-bind="item"
                          :width="item.horizontal ? item.groupPosition.input : null"
                          :show-value="true"
                          :item="item"
                          :class="{
                            'left-icon': item.iconLeft,
                            'right-icon': item.iconRight,
                          }"
                          :validation-rules="{
                            regex: ({ value }) => {
                              const regex = new RegExp(item.partternValue)
                              if(item.type === 'number') {
                                return regex.test(Number(value))
                              }
                              return regex.test(value)
                            }
                          }"
                          @control-value="setValueSchema($event, item, toKhai)"
                          @input="setValueSchema($event, item, toKhai)"
                          @file-upload-complete="fileControl"
                          @file-removed="fileControl"
                        >
                          <template #label="{label}">
                            <div :style="{ width: item.horizontal ? `${item.groupPosition.label}%` : '100%', position: item.horizontal && item.iconLeft ? 'relative' : 'unset' }">
                              <label
                                v-if="item.html"
                                v-html="item.html"
                              />
                              <label
                                v-else-if="item.type !== 'customFile'"
                              >{{ label }} <span
                                v-if="item.validation.includes('required')"
                                class="text-red"
                              >
                                (*)
                              </span></label>
                              <span
                                v-if="item.horizontal && item.iconLeft"
                                class="icon-horizontal"
                                :class="{
                                  'left': item.iconLeft,
                                  'right': item.iconRight,
                                }"
                              >
                                <font-awesome-icon :icon="`fa-solid fa-${item.iconLeft}`" />
                              </span>
                            </div>
                          </template>
                        </FormulateInput>
                        <span
                          v-if="item.horizontal && item.iconRight"
                          class="input-icon-right"
                          :class="{'right': item.iconRight}"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconRight}`" />
                        </span>
                        <span
                          v-if="!item.horizontal && item.iconLeft"
                          class="input-icon"
                          :class="{'left': item.iconLeft}"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconLeft}`" />
                        </span>
                        <span
                          v-if="!item.horizontal && item.iconRight"
                          class="input-icon"
                          :class="{'right': item.iconRight}"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconRight}`" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <FormulateInput
            v-show="false"
            id="submitFormulate"
            v-format-date
            type="submit"
          />
        </FormulateForm>
      </div>

      <div class="text-right mt-2">
        <b-button
          class="mr-1"
          variant="primary"
          size="sm"
          @click="onSaveForm(true)"
        >
          Lưu và biên nhận
        </b-button>
        <b-button
          class="mr-1"
          variant="primary"
          size="sm"
          @click="onSaveForm()"
        >
          Lưu
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="openModal"
        >
          Đóng
        </b-button>

      </div>
      <bien-nhan-ho-so
        ref="bienNhanHoSo"
        @close-page="closePage"
      />
      <commonModal
        ref="commonModal"
        :title="title"
        size="sm"
      >
        Bạn có xác nhận đóng quá trình nhập liệu này không?
        <div
          v-if="isShowFooter"
          slot="footer"
        >
          <router-link :to="this.$route.path.indexOf('tiep-nhan-ho-so/truc-tiep/') >= 0 ? '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep' : '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs'">
            <b-button
              size="sm"
              variant="primary"
              class="mr-1"
            >
              Đồng ý
            </b-button>
          </router-link>
          <b-button
            variant="danger"
            size="sm"
            @click="$refs.commonModal.hideModal()"
          >
            Đóng
          </b-button>
        </div>
      </commonModal>
      <b-modal
        ref="themFileDinhKem"
        :hide-footer="true"
        :hide-header="true"
        :no-close-on-backdrop="true"
        size="lg"
      >
        <div>
          <good-table
            ref="hoSo"
            class="mt-1 fixed-column"
            :columns="columnsTableFile"
            :rows="danhSachFileDinhKem"
            :selectable="true"
            :initial-selected-rows="selectedRows"
            :total="danhSachFileDinhKem.length"
            :is-pagination-client="true"
            @selected-item="onSelectRow"
          >
            <template
              slot="custom-row"
              slot-scope="props"
            >
              <span v-if="props.props.column.field === 'tenFile'">
                <span
                  style="color: #005DB4"
                  @click="downloadFile(props.props.row)"
                >{{ props.props.row.tenFile }}</span>
              </span>
            </template>
          </good-table>
        </div>
        <div>
          <div style="text-align: right; margin-top: 1.5rem">
            <b-button
              style="margin-right: 1rem"
              variant="primary"
              size="sm"
              @click="luuFileDinhKem"
            >
              Lưu
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="hideModal"
            >
              Đóng
            </b-button>
          </div>
        </div>
      </b-modal>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BFormRadioGroup,
  BButton,
  BFormGroup,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import BienNhanHoSo from '@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/BienNhanHoSo.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import END_POINTS from '@/api/endpoints'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, phone, email } from '@validations'
import { ROUTE_NAME } from '@/modules/tiep-nhan-ho-so/constants/constants'
import $ from 'jquery'
import { compareObjects, formatDateTimeRequest } from '@/utils/index'
import commonModal from '@/modules/xu-ly-ho-so/components/modal/CommonModal.vue'
import _cloneDeep from 'lodash/cloneDeep'
import GoodTable from '@/components/GoodTable.vue'

export default {
  name: 'ThemMoiHoSo',
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    commonModal,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
    BFormGroup,
    BCollapse,
    BienNhanHoSo,
    DatePicker,
    Treeselect,
    ValidationProvider,
    ValidationObserver,
    GoodTable,
  },
  data() {
    return {
      danhSachFilesTheoTenCD: null,
      thuTucHanhChinhActive: null,
      indexUploadFile: null,
      fileItem: null,
      chiTietHoSo: null,
      danhSachFileDinhKem: [],
      columnsTableFile: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Tên tệp tải lên',
          field: 'tenFile',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Định dạng',
          field: 'extension',
          width: '200px',
          thClass: 'text-center',
        },
      ],
      selectedRows: [],
      required,
      phone,
      email,
      faTag,
      keyTable: 0,
      isShow: false,
      isShowFooter: false,
      title: 'Xác nhận',
      formulateValidation: false,
      columns: [
        {
          field: '',
          key: 'a',
          title: 'STT',
          width: 50,
          align: 'center',
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: 'tenGiayTo',
          key: 'b',
          title: 'Tên giấy tờ',
          width: 200,
          align: 'left',
        },
        {
          field: 'banChinh',
          key: 'c',
          title: 'Bản chính',
          width: 50,
        },
        {
          field: 'banSao',
          key: 'd',
          title: 'Bản sao',
          width: 50,
        },
        {
          field: 'banPhoto',
          key: 'e',
          title: 'Bản photo',
          width: 50,
        },
        {
          field: 'file',
          key: 'f',
          title: 'File đính kèm',
          width: 100,
          renderBodyCell: ({ rowIndex, row }) => <div class="upload-btn-wrapper">
            <div class="d-flex">
            <div class="wrapper-class mr-2" title="">
              <button class="btn" style='width: 90px; position: relative'>Tải lên
              <input style='width: 80px; position: absolute' type="file" name="myfile" multiple on-change={e => this.changeFileGiayTo(e, rowIndex, row)} accept=".doc,.pdf,.xls,.docx,.xlsx,.zip,.rar,.jpg,.png,.bmp,.gif"/>
             </button>
            </div>
              <div class="wrapper-class" title="" on-click={() => this.taiFile(rowIndex, row)}>
                <button class="btn">Chọn file tải lên</button>
              </div>
            </div>
            <div class="mt-1 cursor-pointer" style="color: #275bb2">
             {this.getFileName(rowIndex, row)}
            </div>
          </div>,
        },
      ],
      danhSachMauHoSo: [],
      danhSachMauHoSoGiayTo: [],
      beginObject: {},
      formModel: {
        name: '',
        dichVuSms: true,
        nguoiNop_CMND_HoChieu: '',
        nguoiNop_HoVaTen: '',
        ngaySinh: null,
        nguoiNop_DiaChi: '',
        nguoiNop_TinhId: null,
        nguoiNop_HuyenId: null,
        nguoiNop_XaId: null,
        nguoiNop_SoDienThoai: '',
        nguoiNop_DiaChiEmail: '',
        kieuNguoiNop: 1,
        kieuChuHoSo: 0,
        chuHoSo_CMND_HoChieu: '',
        chuHoSo_HoVaTen: '',
        chuHoSo_DiaChi: '',
        chuHoSo_TinhId: null,
        chuHoSo_HuyenId: null,
        chuHoSo_XaId: null,
        chuHoSo_SoDienThoai: '',
        chuHoSo_DiaChiEmail: '',
        linhVucId: null,
        lePhi: 0,
        lePhi_DaNop: true,
        phiHs: 0,
        phiHs_DaNop: true,
        thuTucHanhChinhId: null,
        coQuanGiaiQuyetId: '',
        ngayNop: null,
        ngayHenTraKq: null,
        gioHenTraTu: '',
        hoSoGiayToModels: [],
        hoSoGiayToKhacModels: [],
        gioHenTraDen: '',
        kieuNhanKetQua: 0,
        dichVuChuyenPhatKetQua: false,
        chuyenPhat_HoTen: '',
        chuyenPhat_SoDienThoai: '',
        chuyenPhat_TinhId: null,
        chuyenPhat_HuyenId: null,
        chuyenPhat_XaId: null,
        chuyenPhat_DiaChi: '',
        thongTinMota: '',
        mauHoSoId: null,
        lstThongTinToKhai: [],
      },
      tinhThanhPho: [],
      quanHuyen: [],
      quanHuyen1: [],
      quanHuyen2: [],
      xaPhuong: [],
      xaPhuong1: [],
      xaPhuong2: [],
      danhSachLinhVuc: [],
      danhSachThuTucHanhChinh: [],
      hoSoGiayToKhacModels: {
        tenGiayTo: '',
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
        dsHoSoGiayToFileDinhKem: [],
      },
      idDetail: this.$route.params.id,
      listRow: [],
      thongTinToKhai: [],
      formKey: 0,
      danhSachToKhai: [],
      nguoiNopVaChuHoSoGiongNhau: false,
      formControlView: [],
    }
  },
  computed: {
    fileNameGiayToKhac() {
      return this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem
        ? this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem.map(file => file.name || file.tenFileDinhKem)
        : []
    },
  },
  watch: {
    'formModel.linhVucId': function (newVal) {
      if (!newVal) {
        this.formModel.thuTucHanhChinhId = null
        this.formModel.mauHoSoId = null
      }
    },
    'formModel.thuTucHanhChinhId': function (newVal) {
      if (!newVal) {
        this.formModel.mauHoSoId = null
      }
    },
    'formModel.kieuNguoiNop': function () {
      if (!this.formModel.nguoiNop_HoVaTen) {
        this.$refs.CMND.focus()
      }
    },
  },
  created() {
    this.layDanhSachTinhThanhPho()
    this.layDanhSachLinhVuc()
    if (this.idDetail) {
      this.layChiTietHoSo()
    } else {
      this.beginObject = _cloneDeep(this.formModel)
    }
    $(document).ready(() => {
      // eslint-disable-next-line func-names
      $('#phiInput').on('keyup', function () {
        const str = $(this).val()
        const arr = str.split('')
        // eslint-disable-next-line prefer-const
        let out = []
        // eslint-disable-next-line no-plusplus
        for (let cnt = 0; cnt < arr.length; cnt++) {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(arr[cnt]) === false) {
            out.push(arr[cnt])
          }
        }
        const num = Number(out.join(''))
        if (num === 0) {
          $(this).val('')
        } else {
          $(this).val(num.toLocaleString('vi'))
        }
      })
      // eslint-disable-next-line func-names
      $('#lePhiInput').on('keyup', function () {
        const str = $(this).val()
        const arr = str.split('')
        // eslint-disable-next-line prefer-const
        let out = []
        // eslint-disable-next-line no-plusplus
        for (let cnt = 0; cnt < arr.length; cnt++) {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(arr[cnt]) === false) {
            out.push(arr[cnt])
          }
        }
        const num = Number(out.join(''))
        if (num === 0) {
          $(this).val('')
        } else {
          $(this).val(num.toLocaleString('vi'))
        }
      })
    })
  },
  methods: {
    formatDate(dateString) {
      const [day, month, year] = dateString.split('/')
      const date = new Date(Date.UTC(year, month - 1, day))
      const formattedDate = date.toISOString()
      return formattedDate
    },
    kiemtraCongDan() {
      const payload = {
        hoTen: this.formModel.nguoiNop_HoVaTen,
        ngaySinh: this.formatDate(this.formModel.ngaySinh),
        tenDangNhap: this.formModel.nguoiNop_CMND_HoChieu,

      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.KIEM_TRA_CONG_DAN, payload, true).then(response => {
        if (response.data && response.data.succeeded) {
          const { data } = response.data
          this.formModel.nguoiNop_HoVaTen = data.hoTen
          this.formModel.nguoiNop_DiaChi = data.diaChiChiTiet
          this.formModel.nguoiNop_TinhId = data.tinhId
          this.formModel.nguoiNop_HuyenId = data.huyenId
          this.formModel.nguoiNop_XaId = data.xaId
          this.formModel.nguoiNop_DiaChiEmail = data.email ? data.email : this.chiTietHoSo.nguoiNop_DiaChiEmail
          this.formModel.nguoiNop_SoDienThoai = data.sdt ? data.sdt : this.chiTietHoSo.nguoiNop_SoDienThoai
        }
      })
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    openModal() {
      if (compareObjects(this.beginObject, this.formModel)) {
        this.$router.push({
          path: this.$route.path.indexOf('tiep-nhan-ho-so/truc-tiep/') >= 0 ? '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep' : '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs',
        })
      } else {
        this.isShowFooter = true
        this.$refs.commonModal.showModal()
      }
    },
    setValueSchema(value, control, row) {
      if (value) {
        this.thongTinToKhai.forEach((toKhai, index) => {
          if (toKhai.bieuMauId === row.id) {
            const idx = this.thongTinToKhai[index].listControl.findIndex(ctr => ctr.key === control.code)
            if (idx !== -1) {
              this.thongTinToKhai[index].listControl[idx].value = value.toString()
            }
          }
        })
        this.setFormFields(row)
      }
    },
    setFormFields(form) {
      const currentForm = this.danhSachToKhai.find(toKhai => toKhai.id === form.id)
      const controlFlatten = this.thongTinToKhai.reduce((current, next) => [...current, ...next.listControl], [])
      this.formControlView.forEach((formControl, index) => {
        if (formControl.id === form.id) {
          currentForm.listRow.forEach((row, indexRow) => {
            this.formControlView[index].listRow[indexRow].listControl = currentForm.listRow[indexRow].listControl.filter(control => {
              if (control.isHideExpression) {
                let result = true
                const controlCompare = control.hideExpression.replace(/ /g, '').split(':')
                if (controlCompare.length > 0) {
                  controlFlatten.forEach(controlFl => {
                    if (controlFl.key === controlCompare[0] && controlFl.value === controlCompare[1]) {
                      result = false
                    }
                  })
                }
                return result
              }
              return true
            })
          })
        }
      })
    },
    closePage() {
      const url = this.$route.path.indexOf('tiep-nhan-ho-so/truc-tiep/cap-nhat/') >= 0 ? '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep' : '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs'
      this.$router.push({ path: url })
    },
    async onClickToPrint(data) {
      this.$refs.bienNhanHoSo.show(data)
    },
    async layChiTietHoSo() {
      this.$axios.get(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET, { id: this.idDetail }).then(async response => {
        if (response.data && response.data.succeeded) {
          const { data } = response.data
          this.chiTietHoSo = data
          const keys = Object.keys(this.formModel)
          keys.forEach(key => {
            this.formModel[key] = data[key]
          })
          await this.layDanhSachThuTucHanhChinh({ id: data.linhVucId }).then(() => {
            this.formModel.thuTucHanhChinhId = data.thuTucHanhChinhId
          })
          await this.layDanhSachMauHoSo({ id: data.thuTucHanhChinhId }).then(() => {
            this.formModel.mauHoSoId = data.mauHoSoId
          })
          this.layDanhSachToKhai({ id: data.thuTucHanhChinhId })
          this.formModel.ngayNop = data.ngayTiepNhan ? new Date(data.ngayTiepNhan) : new Date()
          this.formModel.ngayHenTraKq = new Date(data.ngayHenTraKq)
          this.formModel.gioHenTraDen = this.setTimeToDate(this.formModel.gioHenTraDen)
          this.formModel.gioHenTraTu = this.setTimeToDate(this.formModel.gioHenTraTu)
          this.$set(this.formModel, 'id', data.id)
          this.beginObject = _cloneDeep(this.formModel)
          this.danhSachMauHoSoGiayTo = data.hoSoGiayToModels
          this.hoSoGiayToKhacModels.tenGiayTo = data.hoSoGiayToKhacModels[0]?.tenGiayTo
          this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem = data.hoSoGiayToKhacModels.length > 0 ? data.hoSoGiayToKhacModels[0].dsHoSoGiayToFileDinhKem : []
          $('#phiInput').val(data.phiHs.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
          $('#lePhiInput').val(data.lePhi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
        }
      })
    },
    layDanhSachTinhThanhPho() {
      this.$axios.get(END_POINTS.COMMON.LAY_DON_VI, { level: 1 }).then(response => {
        if (response.data && response.data.succeeded) {
          this.tinhThanhPho = response.data.data
        }
      })
    },
    layDanhSachQuanHuyen(value, modelValue) {
      if (!value) {
        this.formModel[modelValue.huyenModel] = null
        this.formModel[modelValue.xaModel] = null
      } else {
        this.$axios.get(END_POINTS.COMMON.LAY_DON_VI, { level: 2, idDonViCha: value }).then(response => {
          if (response.data && response.data.succeeded) {
            this[modelValue.danhSachHuyen] = response.data.data
          }
        })
      }
    },
    layDanhSachXaPhuong(value, modelValue) {
      if (!value) {
        this.formModel[modelValue.xaModel] = null
      } else {
        this.$axios.get(END_POINTS.COMMON.LAY_DON_VI, { level: 3, idDonViCha: value }).then(response => {
          if (response.data && response.data.succeeded) {
            this[modelValue.danhSachXa] = response.data.data
          }
        })
      }
    },
    layDanhSachLinhVuc() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX, { phanQuyen: true }).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachLinhVuc = response.data.data
        }
      })
    },
    async layDanhSachThuTucHanhChinh(value) {
      this.formModel.thuTucHanhChinhId = null
      await this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX, { linhVucId: value.id, phanQuyen: true }).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachThuTucHanhChinh = response.data.data
        }
      })
    },
    async layDanhSachMauHoSo(value) {
      if (this.formModel.lePhi && this.formModel.phiHs) {
        this.formModel.lePhi = value.lePhi
        this.formModel.phiHs = value.phi
      }

      this.formModel.mauHoSoId = null
      await this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.MAU_HO_SO, { tthcId: value.id }).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachMauHoSo = response.data.data
        }
      })
    },
    async layDanhSachMauHoSoMauHoSo(value) {
      this.thuTucHanhChinhActive = value
      const payload = {
        maTthc: value.code,
        chuHoSo: {
          soDinhDanh: this.formModel.nguoiNop_CMND_HoChieu,
        },
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.GET_DANH_SACH_FILE, payload).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachFilesTheoTenCD = response.data.data
        }
      })
      if (this.formModel.lePhi && this.formModel.phiHs) {
        this.formModel.lePhi = value.lePhi
        this.formModel.phiHs = value.phi
      }

      this.formModel.mauHoSoId = null
      await this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.MAU_HO_SO, { tthcId: value.id }).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachMauHoSo = response.data.data
          if (this.danhSachMauHoSo && this.danhSachMauHoSo.length === 1) {
            this.formModel.mauHoSoId = this.danhSachMauHoSo[0].id
            this.setMauHoSoGiayTo({ id: this.danhSachMauHoSo[0].id })
          }
        }
      })
    },
    async chiTietToKhai(id) {
      const payload = {
        id: this.formModel.id,
        tthcId: id,

      }

      await this.$axios.get(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET_BIEU_MAU, payload).then(response => {
        if (response.data && response.data.data && response.data.succeeded) {
          const result = response.data.data
          result.forEach(bieuMau => {
            this.danhSachToKhai.forEach((toKhai, indToKhai) => {
              if (bieuMau.bieuMauId === toKhai.id) {
                const keyControls = Object.keys(bieuMau.tableValue[0])
                toKhai.listRow.forEach((row, indRow) => {
                  row.listControl.forEach((control, indControl) => {
                    if (keyControls.includes(control.code)) {
                      this.$set(this.formControlView[indToKhai].listRow[indRow].listControl[indControl], 'value', bieuMau.tableValue[0][control.code])
                      this.formKey += 1
                    }
                  })
                })
                this.thongTinToKhai.forEach((toKhaiChiTiet, index) => {
                  toKhaiChiTiet.listControl.forEach((controlChiTiet, controlIndex) => {
                    if (keyControls.includes(controlChiTiet.key) && indToKhai === index) {
                      this.thongTinToKhai[index].listControl[controlIndex].value = bieuMau.tableValue[0][controlChiTiet.key]
                    }
                  })
                })
              }
            })
          })
        }
      })
    },
    async layDanhSachToKhai(value) {
      await this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.LAY_TO_KHAI, { tthcId: value.id }).then(response => {
        if (response.data && response.data.succeeded) {
          const formData = response.data.data
          formData.forEach(form => {
            const controls = JSON.parse(form.content)
            this.thongTinToKhai.push({
              bieuMauId: form.id,
              tableName: form.tableName,
              listControl: controls
                ? controls.reduce((list, row) => {
                  list.push(...row.listControl.map(control => ({
                    key: control.code,
                    value: '',
                    dbType: control.dbtype,
                  })))
                  return list
                }, [])
                : [],
            })
          })
          const resMap = response.data.data.map(row => ({
            active: true,
            id: row.id,
            name: row.name,
            listRow: JSON.parse(row.content),
          }))
          this.danhSachToKhai = _cloneDeep(resMap)
          this.formControlView = _cloneDeep(resMap)
          if (this.idDetail) {
            this.chiTietToKhai(value.id)
          }
        }
      })
    },
    setPhi(value) {
      $('#phiInput').val(value.phi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
      $('#lePhiInput').val(value.lePhi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
    },
    layNgayHenTraKetQua() {
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.LAY_HAN_TRA_KET_QUA, {
        tthcId: this.formModel.thuTucHanhChinhId,
        mauHoSoId: this.formModel.mauHoSoId,
      }, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.formModel.ngayHenTraKq = new Date(response.data.data.ngayHenTraKq)
          this.formModel.ngayNop = new Date(response.data.data.ngayNop)
        }
      })
    },
    setMauHoSoGiayTo(mauHS) {
      const mauHoSo = mauHS.id
      this.danhSachMauHoSoGiayTo = this.danhSachMauHoSo.find(hoSo => hoSo.id === mauHoSo).mauHoSoGiayTos || []
      this.$nextTick(() => {
        this.layNgayHenTraKetQua()
      })
    },
    normalizer(node) {
      return {
        id: node.donViId || node.id,
        code: node.code || node.maTTHC,
        label: node.tenDonVi || node.name || node.tenMauHoSo || node.tenLinhVuc || node.tenTTHC,
      }
    },
    changeFileGiayToKhac(el) {
      const files = Array.from(el.target.files || el.dataTransfer.files)
      const filesAccept = this.checkFileAccept(files)
      const length = this.checkFileLength(filesAccept)
      const checkSizeFile = filesAccept.some(file => file.size > 25000000)
      if (checkSizeFile) {
        this.$toast.error('File vượt quá dung lượng cho phép')
      } else if (length) {
        this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem.push(...filesAccept)
      }
    },
    taiFile(index, item) {
      this.fileItem = item
      this.indexUploadFile = index
      this.onSelectRow(this.danhSachMauHoSoGiayTo[index].fileUpload)
      this.layDanhSachFile(item)
      this.$refs.themFileDinhKem.show()
    },
    hideModal() {
      this.$refs.themFileDinhKem.hide()
    },
    luuFileDinhKem() {
      this.hideModal()
      this.danhSachMauHoSoGiayTo[this.indexUploadFile].fileUpload = this.selectedRows
      this.keyTable += 1
    },
    layDanhSachFile(itemRow) {
      if (this.danhSachFilesTheoTenCD) {
        if (itemRow && itemRow.fileUpload && itemRow.fileUpload.length > 0) {
          const mapFiles1 = new Map(itemRow.fileUpload.map(file => [file.tenFile, file.vgtSelected]))

          this.danhSachFilesTheoTenCD.forEach(file => {
            if (mapFiles1.has(file.tenFile)) {
              // eslint-disable-next-line no-param-reassign
              file.vgtSelected = mapFiles1.get(file.tenFile)
            }
          })
          this.danhSachFileDinhKem = this.danhSachFilesTheoTenCD.filter(item => item.maGiayTo === itemRow.maGiayTo)
        } else {
          this.danhSachFileDinhKem = this.danhSachFilesTheoTenCD.filter(item => item.maGiayTo === itemRow.maGiayTo)
        }
      }
    },
    downloadFile(item) {
      const payload = {
        maCoQuan: item.coQuanChuQuan,
        url: item.linkFile || item.path,
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DOWNLOAD_FILE, payload).then(response => {
        const tempLink = document.createElement('a')
        tempLink.href = `data:application/pdf;base64,${response.data.data.fileContent}`
        tempLink.setAttribute('download', `${item.tenTep}`)
        tempLink.click()
      })
    },
    changeFileGiayTo(event, index, row) {
      const files = Array.from(event.target.files || event.dataTransfer.files)
      if (row.file) {
        files.push(...row.file)
      }
      const filesAccept = this.checkFileAccept(files)
      const checkSizeFile = filesAccept.some(file => file.size > 25000000)
      const length = this.checkFileLength(filesAccept)
      if (checkSizeFile) {
        this.$toast.error('File vượt quá dung lượng cho phép')
      } else if (length) {
        this.danhSachMauHoSoGiayTo[index].file = filesAccept
        this.keyTable += 1
      }
    },
    checkFileLength(files) {
      if (files.length > 20) {
        this.$toast.error('Không được chọn quá 20 File')
        return false
      }
      return true
    },
    checkFileAccept(files) {
      const filesNot = []
      const filesAccept = []
      files.forEach(file => {
        if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(file.type)) {
          filesAccept.push(file)
        } else {
          filesNot.push(file)
        }
      })
      if (filesNot.length > 0) {
        let filesNotError = ''
        filesNot.forEach(item => {
          filesNotError += `- ${item}\n`
        })
        this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
      }
      return filesAccept
    },
    async uploadFile(files) {
      if (files && files.length > 0) {
        const formData = new FormData()
        files.forEach(file => {
          formData.append('listFileData', file)
        })
        return this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(response => {
          if (response && response.data && response.status === 200) {
            return response.data.map(file => ({
              tenFileDinhKem: file.fileName,
              pathFileDinhKiem: file.objectName,
            }))
          }
          return []
        })
      }
      return []
    },
    async onSaveForm(bienNhan) {
      this.formulateValidation = false
      const submitFormulate = document.getElementById('submitFormulate')
      if (submitFormulate) {
        submitFormulate.click()
      }

      this.setThongTinChuHoSo(!this.nguoiNopVaChuHoSoGiongNhau)
      const validate = await this.$refs.documentRules.validate()
      if (validate) {
        if (this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem.length) {
          const fileOld = this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem.filter(file => file.tenFileDinhKem)
          const fileNew = this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem.filter(file => !file.tenFileDinhKem)
          await this.uploadFile(fileNew).then(response => {
            this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem = [...fileOld, ...response]
            this.formModel.hoSoGiayToKhacModels = [this.hoSoGiayToKhacModels]
          })
        } else {
          this.formModel.hoSoGiayToKhacModels = [this.hoSoGiayToKhacModels]
        }

        const payloadFiles = []
        const results = []
        const mauHoSoLength = this.danhSachMauHoSoGiayTo.length
        for (let i = 0; i < mauHoSoLength; i += 1) {
          results.push(this.uploadFile(this.danhSachMauHoSoGiayTo[i].file))
        }
        const thongTinToKhaiFile = []
        this.thongTinToKhai.forEach((toKhai, index) => {
          this.thongTinToKhai[index].listControl.forEach((control, idx) => {
            if (control.key.includes('UploadFile')) {
              this.thongTinToKhai[index].listControl[idx].value = thongTinToKhaiFile.push(this.uploadFile(control.value))
            }
          })
        })
        await Promise.all(thongTinToKhaiFile).then(result => {
          this.thongTinToKhai.forEach((toKhai, index) => {
            this.thongTinToKhai[index].listControl.forEach((control, idx) => {
              if (control.key.includes('UploadFile')) {
                this.thongTinToKhai[index].listControl[idx].value = [...result].toString()
              }
            })
          })
        })
        await Promise.all(results).then(result => {
          for (let i = 0; i < mauHoSoLength; i += 1) {
            payloadFiles.push({
              ...this.danhSachMauHoSoGiayTo[i],
              dsHoSoGiayToFileDinhKem: [...result[i], ...(this.danhSachMauHoSoGiayTo[i].dsHoSoGiayToFileDinhKem || []), ...(this.danhSachMauHoSoGiayTo[i].fileUpload || [])],
            })
          }
        })

        const mergedData = payloadFiles.map(item => {
          // Xử lý dsHoSoGiayToFileDinhKem
          const combinedFiles = item.dsHoSoGiayToFileDinhKem.flatMap(items => (Array.isArray(items) ? items : [items])).map(file => ({
            tenFileDinhKem: file.tenFileDinhKem || file.tenFile,
            pathFileDinhKiem: file.pathFileDinhKiem || file.linkFile,
            kieuFile: file.tenFile ? 2 : 1,
          }))

          // Loại bỏ thuộc tính file nếu nó là mảng rỗng hoặc chỉ chứa đối tượng rỗng
          const { file, ...rest } = item
          if (Array.isArray(file) && (file.length === 0 || (file.length === 1 && Object.keys(file[0]).length === 0))) {
            return {
              ...rest,
              dsHoSoGiayToFileDinhKem: combinedFiles,
            }
          }
          return {
            ...item,
            dsHoSoGiayToFileDinhKem: combinedFiles,
          }
        })

        this.formModel.hoSoGiayToModels = mergedData.flatMap(array => array).filter(item => Object.keys(item).length > 0)
        this.formModel.lstThongTinToKhai = this.thongTinToKhai
        const gioHenTraTu = this.formatDateToTime(this.formModel.gioHenTraTu)
        const gioHenTraDen = this.formatDateToTime(this.formModel.gioHenTraDen)
        this.formModel.phiHs = $('#phiInput').val() === 0 ? 0 : this.getNumber($('#phiInput').val())
        this.formModel.lePhi = $('#lePhiInput').val() === 0 ? 0 : this.getNumber($('#lePhiInput').val())

        const payload = {
          ...this.formModel,
          gioHenTraTu,
          gioHenTraDen,
        }
        payload.ngayHenTraKq = formatDateTimeRequest(this.formModel.ngayHenTraKq)
        payload.ngayNop = formatDateTimeRequest(this.formModel.ngayNop)
        if (this.idDetail && this.formulateValidation) {
          this.$axios.put(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.SUA, payload).then(response => {
            if (response.data.succeeded) {
              if (bienNhan) {
                this.onClickToPrint(response.data)
              } else {
                let url = '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep'
                if (this.$route.path.indexOf('tiep-nhan-ho-so/truc-tiep/cap-nhat/') < 0) {
                  url = '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs'
                }
                this.$router.push({ path: url })
                // this.$router.replace({ name: ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH })
              }
            }
          })
        } else if (this.formulateValidation) {
          this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.THEM_MOI, payload).then(response => {
            if (response.data.succeeded) {
              if (bienNhan) {
                this.onClickToPrint(response.data)
              } else {
                this.$router.push(ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH)
              }
            }
          })
        }
      } else {
        const first = Object.entries(this.$refs.documentRules.errors).find(item => item[1].length > 0)
        this.handleFocusError(first)
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['linhVuc', 'thuTucHanhChinh', 'mauHoSo'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
    getNumber(str) {
      const arr = str.split('')
      // eslint-disable-next-line prefer-const
      let out = []
      arr.forEach(item => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(item) === false) {
          out.push(item)
        }
      })
      return Number(out.join(''))
    },
    formatDateToTime(date) {
      return date
        ? `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`
        : ''
    },
    setTimeToDate(time) {
      const date = new Date()
      if (time) {
        date.setHours(time.slice(0, 2))
        date.setMinutes(time.slice(3, 5))
        return date
      }
      return ''
    },
    setThongTinChuHoSo(value) {
      if (value) {
        this.quanHuyen1 = this.quanHuyen
        this.xaPhuong1 = this.xaPhuong
        const objectField = {
          chuHoSo_CMND_HoChieu: this.formModel.nguoiNop_CMND_HoChieu,
          chuHoSo_HoVaTen: this.formModel.nguoiNop_HoVaTen,
          chuHoSo_DiaChi: this.formModel.nguoiNop_DiaChi,
          chuHoSo_TinhId: this.formModel.nguoiNop_TinhId,
          chuHoSo_HuyenId: this.formModel.nguoiNop_HuyenId,
          chuHoSo_XaId: this.formModel.nguoiNop_XaId,
          chuHoSo_SoDienThoai: this.formModel.nguoiNop_SoDienThoai,
          chuHoSo_DiaChiEmail: this.formModel.nguoiNop_DiaChiEmail,
          kieuChuHoSo: this.formModel.kieuNguoiNop,
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const field in objectField) {
          if (objectField[field]) {
            this.formModel[field] = objectField[field]
          }
        }
      }
    },
    removeFile(name) {
      this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem = this.hoSoGiayToKhacModels.dsHoSoGiayToFileDinhKem.filter(file => {
        if (file.name) {
          return file.name !== name
        }
        if (file.tenFileDinhKem) {
          return file.tenFileDinhKem !== name
        }
        return file
      })
    },
    getFileName(rowIndex, row) {
      const fileList = [...(row.dsHoSoGiayToFileDinhKem || []), ...(row.file || []), ...(row.fileUpload || [])]
      if (fileList) {
        return <div>
          {fileList.map((file, id) => <div key={id}>
            {file.name || file.tenFileDinhKem || file.tenFile}
            <feather-icon
                style="color: red"
                icon="Trash2Icon"
                size="12"
                class="ml-1"
                onClick={() => { this.removeFileGiayTo(rowIndex, file.name || file.tenFileDinhKem || file.tenFile) }}
            />
          </div>)}
        </div>
      }
      return ''
    },
    removeFileGiayTo(rowIndex, fileName) {
      const newFile = this.danhSachMauHoSoGiayTo[rowIndex].file
      const oldFile = this.danhSachMauHoSoGiayTo[rowIndex].dsHoSoGiayToFileDinhKem
      const { fileUpload } = this.danhSachMauHoSoGiayTo[rowIndex]
      if (newFile) {
        this.danhSachMauHoSoGiayTo[rowIndex].file = this.danhSachMauHoSoGiayTo[rowIndex].file.filter(file => {
          if (file.name) {
            return file.name !== fileName
          }
          if (file.tenFile) {
            return file.tenFile !== fileName
          }
          if (file.tenFileDinhKem) {
            return file.tenFileDinhKem !== fileName
          }
          return file
        })
      }
      if (oldFile) {
        this.danhSachMauHoSoGiayTo[rowIndex].dsHoSoGiayToFileDinhKem = this.danhSachMauHoSoGiayTo[rowIndex].dsHoSoGiayToFileDinhKem.filter(file => {
          if (file.name) {
            return file.name !== fileName
          }
          if (file.tenFile) {
            return file.tenFile !== fileName
          }
          if (file.tenFileDinhKem) {
            return file.tenFileDinhKem !== fileName
          }
          return file
        })
      }
      if (fileUpload) {
        this.danhSachMauHoSoGiayTo[rowIndex].fileUpload = this.danhSachMauHoSoGiayTo[rowIndex].fileUpload.filter(file => {
          if (file.name) {
            return file.name !== fileName
          }
          if (file.tenFile) {
            return file.tenFile !== fileName
          }
          if (file.tenFileDinhKem) {
            return file.tenFileDinhKem !== fileName
          }
          return file
        })
      }

      this.keyTable += 1
    },
    disableTimeTraTu(date) {
      if (this.formModel.gioHenTraDen) {
        return date > this.formModel.gioHenTraDen
      }
      return false
    },
    disableTimeTraDen(date) {
      if (this.formModel.gioHenTraTu) {
        return date < this.formModel.gioHenTraTu
      }
      return false
    },
    fileControl() {
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/modules/tiep-nhan-ho-so/assets/styles/index";
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}

.file-color {
  color: #4488e3;
  svg {
    margin-top: 4px;
  }
}
.text-red{
  color:red;
}
 .color-hr{
    border-top: 1px solid #005DB4 !important;
  }
  .color-text{
    color: #005DB4 !important;
    margin-left: 12px;
    font-size: 16px;
  }
  h5{ margin-bottom: 0rem !important;}
  img {
    width: 18px;
    margin-bottom: 4px;
  }
  .text-weight{
    font-weight:700;
  }
  .form-hr{
    margin-bottom: 4px;
  }
</style>
